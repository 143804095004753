<template>
    <!--新增、编辑-->
    <el-dialog :title="method == 0?'新增通知公告':(method == 1?'编辑通知公告':'查看通知公告')" width="60%" :close-on-click-modal="false"
               :visible.sync="visible" v-if="visible">
        <el-form size="small" :model="addEditForm" ref="addEditForm" label-width="140px" class="p_r2 dialogFormBox"
                 :disabled="method==2">
            <el-form-item label="标题：" prop="noticeAnnouncementTitle"
                          :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]">
                <el-input size="small" v-model.trim="addEditForm.noticeAnnouncementTitle" maxlength="50"
                          placeholder="请输入通知公告标题（限50字）" clearable></el-input>
            </el-form-item>
            <el-form-item label="分类：" prop="announcementCategoryId"
                          :rules="[{ required: true, message: '请选择分类', trigger: 'change' }]">
                <el-select size="small" v-model="addEditForm.announcementCategoryId" placeholder="请选择分类" clearable>
                    <el-option
                            v-for="item in noticeCategoryOpt"
                            :key="item.id"
                            :label="item.announcementCategoryName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="外链：" prop="linkUrl">
                <el-input size="small" v-model.trim="addEditForm.linkUrl" maxlength="255"
                          placeholder="请输入外链（限255字）" clearable></el-input>
            </el-form-item>
            <el-form-item label="内容：" prop="content">
                <div v-if="method == 2" v-html="addEditForm.content"></div>
                <Editor v-else :editortext="addEditForm.content" :disMenus="'video'" ref="noticeEditor"
                        @changeHtml="noticeGetEditor"></Editor>
            </el-form-item>
            <!--<el-form-item label="状态：" prop="state" v-if="hasPermission('notice:enable') && method != 0">
                <el-switch v-model="addEditForm.state"
                           :active-value="'0'"
                           :inactive-value="'1'" :disabled="editInfo.reviewedState != 2"
                ></el-switch>
            </el-form-item>-->
            <el-form-item v-if="method != 0" label="添加人：">{{editInfo.createUser}}</el-form-item>
            <el-form-item v-if="method != 0" label="审核状态：">{{$dictUtils.getDictLabel("new_auditStatus", editInfo.reviewedState ,'')}}</el-form-item>
            <el-form-item v-if="method == 2 && editInfo.reviewedState != 0" label="审核：">{{editInfo.reviewedUser}}</el-form-item>
            <el-form-item v-if="method == 2 && editInfo.reviewedState != 0" label="审核备注：">{{editInfo.reviewedRemake}}</el-form-item>
            <el-form-item v-if="method != 0" label="添加时间：">{{editInfo.createTime}}</el-form-item>
            <el-form-item v-if="method != 0" label="最后修改时间：">{{editInfo.updateTime}}</el-form-item>
            <el-form-item v-if="method == 2 && editInfo.reviewedState != 0" label="审核时间：">{{editInfo.reviewedTime}}</el-form-item>
            <el-form-item v-if="method != 0" label="发布时间：">{{editInfo.releaseTime}}</el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">关闭</el-button>
                <el-button size="small" type="primary" @click="submit()" v-noMoreClick v-if="method != 2">提交</el-button>
            </span>
    </el-dialog>
</template>

<script>
    import Editor from '@/components/editor/editor'; // 导入富文本上传组件

    export default {
        components: {Editor},
        data() {
            return {
                visible: false,
                method: null,
                addEditForm: {
                    id: '',
                    content: '',
                    noticeAnnouncementTitle: '',
                    announcementCategoryId: '',
                    releaseTime: '',
                    state: '1',
                    linkUrl: '',
                },
                noticeCategoryOpt: [],
                editInfo: {},
            }
        },
        methods: {
            init(row, method) {
                this.getClassify();
                this.visible = true
                this.method = method
                this.addEditForm = {
                    id: '',
                    content: '',
                    noticeAnnouncementTitle: '',
                    announcementCategoryId: '',
                    releaseTime: '',
                    state: '1',
                    linkUrl: '',
                }
                this.$nextTick(() => {
                    this.$refs.addEditForm.resetFields();
                    if (method != 0) {
                        this.recover(this.addEditForm, row);
                        this.editInfo = row
                    }
                })
            },
            noticeGetEditor(data) {
                if (data != '<p><br></p>') {
                    this.addEditForm.content = data;
                    this.$refs.addEditForm.clearValidate('content');
                } else {
                    this.addEditForm.content = '';
                }
            },
            // 获取分类
            getClassify() {
                this.$axios(this.api.website.allWebsiteAnnouncementCategory).then((res) => {
                    if (res.status) {
                        this.noticeCategoryOpt = res.data
                    } else {
                        this.$message.error('查询失败');
                    }
                })
            },
            // 提交
            submit() {
                this.$refs['addEditForm'].validate((valid) => {
                    if (valid) {
                        let {linkUrl, content} = this.addEditForm
                        if (!content) {
                            this.$set(this.addEditForm, "content", '')
                        }
                        if (linkUrl == '' && content == '') {
                            this.$message.warning('外链或内容选填一项');
                            return
                        }
                        let apiStr = 'websitenoticeannouncementSave',
                            type = 'post'
                        if (this.method == '1') {  // 编辑
                            apiStr = 'websitenoticeannouncementUpdateById'
                            type = 'put'
                        }
                        this.$axios(this.api.website[apiStr], this.addEditForm, type).then(res => {
                            if (res.status) {
                                this.$message.success('提交成功')
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
